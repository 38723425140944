<template>
    <div>
        <b-form-group :label="$t('country')">
            <country-selectbox v-model="country_id"></country-selectbox>
        </b-form-group>
        <div class="border rounded p-2" :class="validateError != '' ? 'box-border-color':''">
            <label>{{ $t('universities') }}</label>
            <b-input v-model="universitiesFilterInput"
                     class="mb-0"
                     :placeholder="$t('search')"
                     style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"
            />
            <div class="universities-checkbox-div">
                <b-form-group>
                    <div class="university-checkbox-list">
                        <b-form-checkbox v-model="selected"
                                         name="createFormUniversities"
                                         :value="university.id"
                                         v-for="(university) in universitiesFilterData"
                                         :key="university.id"
                                         @change="handleInput">
                            {{ university.name }}
                        </b-form-checkbox>
                    </div>
                </b-form-group>
            </div>
            <div class="d-flex mt-1">
                <b-badge variant="dark"
                         class="mr-1 pl-3 pr-3 cursor-pointer"
                         @click="selectAll"
                >
                    {{ $t('select_all') }}
                </b-badge>
                <b-badge variant="danger"
                         class="mr-2 pl-3 pr-3 cursor-pointer"
                         @click="clear">
                    {{ $t('clear') }}
                </b-badge>
            </div>
        </div>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"></span>
    </div>
</template>
<script>
    // Component
    import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox'

    // Services
    import UniversityService from '@/services/UniversityService'

    // Other
    import qs from 'qs'

    export default {
        props: {
            country: {
                type: Number,
                default: null
            },
            value: {
                default: null
            },
            validateError: {
                type: String,
                default: ''
            }
        },
        components: {
            CountrySelectbox
        },
        data() {
            return {
                selected: this.value,
                country_id: null,
                universitiesFilterInput: null,
                universities: []
            }
        },
        computed: {
            universitiesFilterData() {
                return this.universities.filter(item => {
                    return item.name.toLowerCase().indexOf(this.universitiesFilterInput.toLowerCase()) > -1
                })
            }
        },
        watch: {
            country: function(val) {
                this.country_id = val
            },
            country_id: {
                handler: function(val) {
                    this.getUniversities(val)
                }
            },
            value: function(newValue) {
                this.selected = newValue
            }
        },
        methods: {
            async getUniversities(countryId = null) {
                this.universities = [];

                if(countryId==null) return;

                let config = {
                    params: {
                        filter: {
                            country_id: countryId
                        },
                        sort: this.getLocaleField('name'),
                        page: 1,
                        limit: 10000
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                UniversityService.getAll(config)
                                 .then((response) => {
                                     const data = response.data.data;
                                     data.map(item => {
                                         this.universities.push({
                                             id: item.id,
                                             name: this.getLocaleText(item, 'name')
                                         });
                                     });

                                     this.universitiesFilterInput = '';
                                 })
            },
            selectAll() {
                this.selected = this.universities.map(item => item.id);
                this.$emit('input', this.selected)
            },
            clear() {
                this.selected = []
                this.$emit('input', this.selected)
            },

            handleInput(event) {
                this.$emit('input', event)
            }
        }
    };
</script>

<style type="scss">
    .universities-checkbox-div {
        border: 1px solid #e9ecef;
        border-top: 0;
        border-bottom-right-radius: .375rem;
        border-bottom-left-radius: .375rem;
        height: 350px;
        padding: 15px;
        overflow-x: auto;
    }
</style>
