<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('university_grade_scale_matching')"
                        :isNewButton="checkPermission('universitygrade_store')"
                        :isColumns="true"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('university_grade_scale_matching')"
                              :isNewButton="checkPermission('universitygrade_store')"
                              :isColumns="true"
                              @new-button-click="createFormModalShow()"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="2" lg="2">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number"
                                          v-model="datatable.queryParams.filter.id"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="5" lg="4">
                        <b-form-group :label="$t('grade_scala')">
                            <b-form-select v-model="datatable.queryParams.filter.grade_conversion_id"
                                           :options="gradeConversionOptions"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="5" lg="6">
                        <b-form-group :label="$t('country')">
                            <country-selectbox v-model="datatable.queryParams.filter.country_id"/>
                        </b-form-group>
                        <b-form-group :label="$t('university')">
                            <university-selectbox v-model="datatable.queryParams.filter.university_id"
                                                  :country-id="datatable.queryParams.filter.country_id"
                                                  :is-country-required="true"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="formModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    <span v-if="formProcess=='show'">{{ $t('score_information').toUpper() }}</span>
                    <span v-if="formProcess=='create'">{{ $t('new').toUpper() }}</span>
                    <span v-if="formProcess=='update'">{{ $t('edit').toUpper() }}</span>
                </template>
                <template v-slot:CommonModalContent>
                    <create-form :gradeConversionOptions="gradeConversionOptions"
                                 @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                    <update-form :formId="formId"
                                 :gradeConversionOptions="gradeConversionOptions"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Components
    import CommonModal from '@/components/elements/CommonModal';
    import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox';
    import Datatable from '@/components/datatable/Datatable';
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import UniversitySelectbox from '@/components/interactive-fields/UniversitySelectbox';

    // Pages
    import CreateForm from './CreateForm';
    import UpdateForm from './UpdateForm';

    // Services
    import GradeConversionService from '@/services/GradeConversionService'
    import UniversityGrades from '@/services/UniversityGrades'

    // Others
    import qs from 'qs'

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            CommonModal,
            CountrySelectbox,
            Datatable,
            DatatableFilter,
            UniversitySelectbox,

            CreateForm,
            UpdateForm
        },
        metaInfo() {
            return {
                title: this.$t('university_grade_scale_matching')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: 'universitygrade_update',
                                    callback: (row) => {
                                        this.updateFormShow(row.id)
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: 'universitygrade_delete',
                                    callback: (row) => {
                                        this.formDelete(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id').toUpper(),
                            field: 'id',
                            hidden: false
                        },
                        {
                            label: this.spaceToBR(this.$t('grade_scala').toUpper()),
                            field: 'grade_conversion_name',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.spaceToBR(this.$t('country').toUpper()),
                            field: this.getLocaleField('university_country_name'),
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.spaceToBR(this.$t('university').toUpper()),
                            field: this.getLocaleField('university_name'),
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.spaceToBR(this.$t('created_by').toUpper()),
                            field: 'created_by',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.spaceToBR(this.$t('created_at').toUpper()),
                            field: 'created_at',
                            hidden: false,
                            sortable: false,
                            formatFn: (col) => {
                                return this.toLocaleDate(col);
                            }
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        page: 1,
                        limit: 20,
                        order: '-id'
                    }
                },
                formProcess: null,
                formId: null,

                gradeConversionOptions: []
            }
        },
        created() {
            this.getGradeConversionOptions()
        },
        methods: {
            filterSet() {
                this.datatable.queryParams.filter = {}
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                return UniversityGrades.getAll(config)
                                       .then((response) => {
                                           this.datatable.rows = response.data.data
                                           this.datatable.total = response.data.pagination.total
                                       })
                                       .finally(() => {
                                           this.datatable.isLoading = false
                                       })
            },

            async getGradeConversionOptions() {
                let config = {
                    params: {
                        sort: 'name',
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                this.gradeConversionOptions = [{value: null, text: this.$t('select')}];
                GradeConversionService.getAll(config)
                                      .then((response) => {
                                          const data = response.data.data;
                                          data.map(item => {
                                              this.gradeConversionOptions.push({
                                                  value: item.id,
                                                  text: this.getLocaleText(item, 'name')
                                              });
                                          });
                                      });
            },

            // Clear
            formClear() {
                this.formId = null
                this.formProcess = null
            },

            // Show
            showForm(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.formModal.$refs.commonModal.show()
            },

            // Create
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.formModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },

            // Delete
            formDelete(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            UniversityGrades.del(id)
                                            .then(response => {
                                                this.$toast.success(this.$t('api.' + response.data.message));
                                                this.getRows();
                                                this.formClear();
                                            })
                                            .catch(error => {
                                                this.$toast.error(this.$t('api.' + error.data.message));
                                            })
                        }
                    })
            }
        }
    }
</script>

